import { gql } from '@apollo/client';

import {
	LOCALIZED_STRING_FRAGMENT,
	LOCALIZED_SB_FRAGMENT,
	TAGS_QUERIES_ARTICLE,
	LOCALIZED_SHORT_SB_FRAGMENT,
} from '@store/fragments';

const COMMENT_FRAGMENT = gql`
	fragment CommentFragmentPost on Comment {
		id
		application
		text
		created
		objectId
		isDeleted
		edited
		isReplyToTopLevelComment
		user {
			name
			avatar {
				webp(width: 80, height: 80, quality: 80)
			}
			id
			roles {
				ID
			}
		}
		objectClass
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		threadCommentsCount
		childThread(
			input: { sort: OLDEST, pagination: { ByCursor: { first: 3 } } }
		) {
			items {
				id
				application
				text
				created
				threadId
				edited
				isDeleted
				isReplyToTopLevelComment
				objectClass
				objectId
				user {
					id
					name
					avatar {
						webp(width: 80, height: 80, quality: 80)
					}
					roles {
						ID
					}
				}
				reactions {
					userReaction {
						objectClass
						objectID
						reactionType
					}
					likeCount
					dislikeCount
				}
				parentComment {
					id
					isDeleted
					user {
						id
						name
					}
				}
				threadComment {
					id
					user {
						id
						name
					}
				}
			}
			paginationInfo {
				... on PaginationByCursor {
					totalItemsCount
					endCursor
					hasNextPage
				}
			}
		}
	}
`;

const AUTHOR_FRAGMENT = gql`
	fragment Author on Author {
		user {
			id
			nickname
			name
			avatar {
				url
			}
		}
	}
`;

const POST_FRAGMENT = gql`
	${AUTHOR_FRAGMENT}
	fragment Post on Post {
		id
		hru
		title {
			defaultValue(language: $language)
		}
		mainPhoto {
			url
		}
		publicationTime
		commentsCount
		link
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		seoNew {
			description
		}
		mainSport {
			name {
				defaultValue(language: $language)
			}
			path
			id
		}
		author {
			...Author
		}
		displayAuthor {
			...Author
		}
		status
		label
		blog {
			id
			hru
			title
			avatar {
				url
			}
			founder {
				id
				name
				nickname
			}
		}
		structuredDescription {
			defaultValue(language: $language) {
				value {
					... on BodyParagraph {
						elements {
							value {
								... on BodyText {
									text
								}
							}
						}
					}
				}
			}
		}
	}
`;

const BLOG_POST_DESCRIPTION_FRAGMENT = gql`
	fragment blogPostDescription on BodyTopElement {
		value {
			... on BodyParagraph {
				elements {
					value {
						... on BodyText {
							text
						}
					}
				}
			}
		}
	}
`;

const LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT = gql`
	${BLOG_POST_DESCRIPTION_FRAGMENT}
	fragment localizedBlogPostDescription on LocalizedSB {
		en {
			...blogPostDescription
		}
		es {
			...blogPostDescription
		}
		it {
			...blogPostDescription
		}
		fr {
			...blogPostDescription
		}
		de {
			...blogPostDescription
		}
		ar {
			...blogPostDescription
		}
	}
`;

export default {
	getPostByHru: gql`
		${COMMENT_FRAGMENT},
		${TAGS_QUERIES_ARTICLE},
		${LOCALIZED_SB_FRAGMENT},
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_SHORT_SB_FRAGMENT}
		query getPostByHru($id: ID!) {
			postQueries {
				get(input: {id: $id}) {
					id
					hru
					sports {
						id
						path
						name {
							...localizedString
						}
					}
					author {
						user {
							id
							name
							nickname
							avatar {
								webp(width: 60, height: 60, quality: 80) 
							}
						}
					}
					mainPhoto {
						url
						meta {
							height
							width
						} 
					}
					topPhoto {
						url
						meta {
							height
							width
						} 
					}
					status
					mainSport {
						id
						name {
							...localizedString
						}
						path
					}
					mainSport {
						id
						path
					}
					tags {
						...TagsQueryFragmentForArticle
					}
					structuredBody {
						...LocalizedSB
					}
					structuredDescription {
						...LocalizedShortSB
					}
					commentsDisabled
					title {
						...localizedString
					}
					publicationTime
					createdTime
					modifiedTime
					commentsCount
					reactions {
						userReaction {
							objectClass
							objectID
							reactionType
						}
						likeCount
						dislikeCount
					}
					topLevelComments(input: {sort: BEST, pagination: { ByCursor: { first: 50 } }} ) {
						items {
							...CommentFragmentPost
						}
						paginationInfo {
							... on PaginationByCursor {
								totalItemsCount
								hasNextPage
								endCursor
							}
						}
					}
					seoNew {
						title
						canonical
						description 
					}
					link
					shareInfo {
						title
						picture {
							url
						}
					}
				}
			}
		}`,

	getPostList: gql`
		${POST_FRAGMENT}
		query getPostList(
			$tag: TagIDsInput
			$page: Int = 1
			$pageSize: Int = 10
			$language: Language
			$blogID: String
			$main: Boolean
			$mainInSection: Boolean
			$status: DocumentStatus = PUBLISHED
			$sport: [SportID!]
			$author: [ID!]
			$minRating: Int
			$sort: PostSortInput
		) {
			postQueries {
				feed(
					input: {
						tag: $tag
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $language
						main: $main
						mainInSection: $mainInSection
						blogID: $blogID
						status: $status
						sport: $sport
						author: $author
						minRating: $minRating
						sort: $sort
					}
				) {
					items {
						...Post
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
							totalItemsCount
						}
					}
				}
			}
		}
	`,

	getPopularFeedPosts: gql`
		${POST_FRAGMENT}
		query getPopularFeedPosts(
			$tag: TagIDsInput
			$page: Int = 1
			$pageSize: Int = 10
			$sport: SportID
			$language: Language
		) {
			postQueries {
				feedPopular(
					input: {
						tag: $tag
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						sport: $sport
					}
				) {
					items {
						...Post
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
							totalItemsCount
							hasNextPage
							firstPage
						}
					}
				}
			}
		}
	`,

	getTopPost: gql`
		${AUTHOR_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		query getTopPost(
			$page: Int = 1
			$pageSize: Int = 10
			$language: Language
			$main: Boolean
			$top: Boolean
			$sportType: [SportID!]
			$status: DocumentStatus = PUBLISHED
		) {
			postQueries {
				feed(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $language
						main: $main
						top: $top
						status: $status
						sport: $sportType
					}
				) {
					items {
						id
						hru
						title {
							...localizedString
						}
						publicationTime
						top
						likesCount
						commentsCount
						link
						mainPhoto {
							url
						}
						author {
							...Author
						}
						displayAuthor {
							...Author
						}
						mainSport {
							name {
								defaultValue(language: $language)
							}
							path
							id
						}
						topPhoto {
							url
						}
						blog {
							id
							hru
							title
							avatar {
								url
							}
							founder {
								id
								name
								nickname
							}
						}
						likesCount
						commentsCount
						status
						label
						structuredDescription {
							...localizedBlogPostDescription
						}
					}
					paginationInfo {
						__typename
						... on PaginationByPage {
							currentPage
							lastPage
						}
					}
				}
			}
		}
	`,
	getPopularNotEditorialPostList: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		${AUTHOR_FRAGMENT}
		query getPopularNotEditorialPostList(
			$page: Int = 1
			$pageSize: Int = 7
			$sportId: SportID
			$minRating: Int = 15
			$language: Language
		) {
			postQueries {
				feedNonEditorialPopular(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						sport: $sportId
						minRating: $minRating
					}
				) {
					items {
						id
						hru
						tags {
							id
							hru
							type
							logo {
								url
							}
							sport {
								path
							}
							extra {
								... on TagTeamExtra {
									type
									country {
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
								}
								... on TagPersonExtra {
									citizenship {
										name
										picture(productType: TRIBUNA, format: PNG) {
											resize
										}
									}
								}
							}
							statObject {
								... on statPlayer {
									id
									currentClub {
										logo {
											resize
										}
										tag {
											logo {
												url
											}
										}
									}
									careers {
										active
										team {
											name
											picture(productType: TRIBUNA, format: LOGO) {
												resize
											}
										}
									}
									tag {
										hru
									}
									name: coalesceName(names: ["name", "lastName", "firstName"])
									coverage {
										isCovered: career
									}
									avatar {
										main: resize(width: "60", height: "60")
									}
								}
								... on statTeam {
									country {
										picture(productType: TRIBUNA, format: PNG) {
											main
										}
									}
									id
									tag {
										hru
									}
									name
									type
									coverage {
										roster
										matches
										isCovered: matches
									}
									logo {
										main: resize(width: "60", height: "60")
									}
								}
								... on statTournament {
									id
									tag {
										hru
									}
									name
									coverage {
										isCovered: season
									}
									logo {
										main: resize(width: "60", height: "60")
									}
								}
							}
						}
						title {
							...localizedString
						}
						publicationTime
						commentsCount
						link
						reactions {
							likeCount
							dislikeCount
						}
						mainSport {
							path
							id
							name {
								defaultValue(language: $language)
							}
						}
						author {
							...Author
						}
						blog {
							id
							hru
							title
						}
						structuredDescription {
							...localizedBlogPostDescription
						}
					}
				}
			}
		}
	`,
};
